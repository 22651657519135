import * as React from "react"
import './src/styles/global.css'

import Layout from './src/components/Layout';
import type {GatsbyBrowser} from "gatsby";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = (
    {
        element,
    }) => {
    return (
        <Layout>
            {element}
        </Layout>
    );
}