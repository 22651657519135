import React, {useEffect, useState} from 'react';
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import {FaBars, FaTimes} from 'react-icons/fa';

type NavItemProps = {
    to: string,
    children: React.ReactNode,
    onClick: () => void
}

const NavItem: React.FC<NavItemProps> = ({ to, onClick, children }) => {
    return (
        <li><Link to={to} onClick={onClick} activeClassName="font-bold">{children}</Link></li>
    );
};

export type LayoutProps = {
    children: React.ReactNode,
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const toggleNav = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.pageYOffset > 0;
            setIsScrolled(isScrolled);
        };

        // Attach the event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array means this effect runs once on mount and clean up on unmount


    return (
        <div className="flex flex-col min-h-screen h-24">
            <nav className={`z-50 fixed top-0 bg-white w-full${isScrolled ? ' shadow' : ''}`}>
                <div className={`flex flex-col lg:flex-row items-center justify-between lg:container mx-auto py-4`}>
                    <div className={"flex"}>
                        <div>
                            <StaticImage
                                src={'../images/logo.png'}
                                width={350}
                                alt={'logo'}
                                className={"max-w-auto"}
                            />
                        </div>
                        <div className={`flex lg:hidden`}>
                            <button onClick={toggleNav}>
                                {isOpen ? <FaTimes className="h-6 w-6" /> : <FaBars className="h-6 w-6" />}
                            </button>
                        </div>
                    </div>
                    <ul className={`bg-white flex flex-col lg:flex-row py-4 items-center gap-6 text-gray-600 uppercase text-sm ${isOpen ? 'flex' : 'hidden'} lg:flex`}>
                        <NavItem to="/" onClick={toggleNav}>Home</NavItem>
                        <NavItem to="/locatie" onClick={toggleNav}>Locatie</NavItem>
                        <NavItem to="/zeewolde" onClick={toggleNav}>Zeewolde</NavItem>
                        <NavItem to="/woningen" onClick={toggleNav}>Woningen</NavItem>
                        <NavItem to="/beschikbaar" onClick={toggleNav}>Beschikbaar</NavItem>
                        <NavItem to="/extra" onClick={toggleNav}>Extra</NavItem>
                        <NavItem to="/downloads" onClick={toggleNav}>Downloads</NavItem>
                        <li>
                            <Link
                                to={"/inschrijven"}
                                onClick={toggleNav}
                                activeClassName="font-bold"
                                className="bg-primary text-light px-4 py-2 hover:bg-secondary transition-colors duration-200"
                            >
                                Inschrijven
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
            <main className="flex-grow pt-24">
                {children}
            </main>
            <footer className="bg-light p-2 text-gray">
                <div className="container mx-auto grid md:grid-cols-2 grid-cols-1 gap-4 py-4">
                    <div className="grid lg:grid-cols-2 items-center gap-8 text-center lg:text-left">
                        <StaticImage
                            height={80}
                            layout={"fixed"}
                            src={"../images/logos/de-realisatie@2x.png"}
                            alt="Logo de Realisatie"
                            className={"mx-auto lg:mx-0"}
                        />
                        <address>
                            <a href="http://www.de-realisatie.nl" target={"_blank"} className="text-gray">www.de-realisatie.nl</a><br />
                            <a href="mailto:info@de-realisatie.nl" target={"_blank"} className="text-gray">info@de-realisatie.nl</a>
                        </address>
                    </div>
                    <div className="grid lg:grid-cols-2 items-center gap-8 text-center lg:text-left">
                        <StaticImage
                            height={80}
                            layout={"fixed"}
                            src={"../images/logos/dna.png"}
                            alt="Logo DNA Makelaars"
                            className={"mx-auto lg:mx-0"}
                        />
                        <address>
                            Antennestraat 86<br />
                            1322 AS Almere<br />
                            <a href="tel:036-7370591" target={"_blank"} className="text-gray">036-7370591</a><br />
                            <a href="mailto:info@dna-makelaars.nl" target={"_blank"} className="text-gray">info@dna-makelaars.nl</a>
                        </address>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Layout;